import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator'
import { asDTO } from '@decet/core/shared/common'

export class EcommerceCarritoProducto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  nombre!: string

  @IsString()
  codigo!: string

  @IsNumber()
  cantidad!: number

  @IsString()
  codigoEcommerce!: string

  @IsString()
  codigoBarras!: string

  @IsNumber()
  precioLista!: number

  @IsNumber()
  precioFinal!: number

  @IsString()
  img!: string

  @IsBoolean()
  espera!: boolean
}

export const EcommerceCarritoProductoDto = asDTO(EcommerceCarritoProducto)

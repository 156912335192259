import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { ApiConfigService } from './api-config.service'

@Injectable({ providedIn: 'root' })
export class SesionService {
  get workspaceId(): number | null {
    return this._workspaceId
  }

  set workspaceId(value: number | null) {
    this._workspaceId = value
  }

  get empresaId(): number | null {
    return this._empresaId
  }

  set empresaId(value: number | null) {
    this._empresaId = value
  }

  get proyectoId(): number | null {
    return this._proyectoId
  }

  set proyectoId(value: number | null) {
    this._proyectoId = value
  }

  private workspaces: any[] = []
  private empresas: any[] = []
  private proyectos: any[] = []
  private _workspaceId: number | null = null
  private _empresaId: number | null = null
  private _proyectoId: number | null = null
  private base = ''

  constructor(apiConfigService: ApiConfigService) {
    this.base = apiConfigService.apiUrl ?? ''
  }

  current(): Observable<any> {
    return of([''])
  }

  url(url: string): string {
    if (url[0] === '/') {
      url = url.slice(1)
    }
    return `${this.base}/api/${this.workspaceId}/${this.empresaId}/${this.proyectoId}/${url}`
  }

  urlCompany(url: string): string {
    if (url[0] === '/') {
      url = url.slice(1)
    }
    return `${this.base}/api/${this.workspaceId}/${this.empresaId}/${url}`
  }

  urlWorkspace(url: string): string {
    if (url[0] === '/') {
      url = url.slice(1)
    }
    return `${this.base}/api/${this.workspaceId}/${url}`
  }

  onlyUrl(url: string): string {
    if (url[0] === '/') {
      url = url.slice(1)
    }
    return `${this.base}/api/${url}`
  }
}

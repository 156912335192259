import { Type } from 'class-transformer'
import { IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { EcommerceCatalogoAgrupacionDto, EcommerceCatalogoAgrupacion } from '@decet/ecommerce-shared'

export class EcommerceProductosDto {
  @IsString()
  id!: string

  @IsString()
  nombre!: string

  @IsString()
  codigo!: string

  @IsString()
  codigoBarras!: string

  @IsString()
  keys!: string

  @IsNumber()
  precio!: number

  @IsNumber()
  descuento!: number

  img!: any

  @IsOptional()
  recomendaciones?: any

  @IsOptional()
  unidadMedida?: any

  @IsOptional()
  @IsString()
  contenido?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => EcommerceCatalogoAgrupacionDto)
  agrupacion?: EcommerceCatalogoAgrupacion

  @IsOptional()
  @ValidateNested()
  @Type(() => EcommerceCatalogoAgrupacionDto)
  nivel1?: EcommerceCatalogoAgrupacion

  @IsOptional()
  @ValidateNested()
  @Type(() => EcommerceCatalogoAgrupacionDto)
  nivel2?: EcommerceCatalogoAgrupacion
}

export class EcommerceProductoParamsDto {
  @IsString()
  nivel1!: string

  @IsString()
  nivel2!: string

  @IsString()
  agrupacion!: string
  @IsString()
  nivel1Cod!: string

  @IsString()
  nivel2Cod!: string

  @IsNumber()
  offset!: number

  @IsNumber()
  limit!: number

  @IsString()
  orderCodigo!: string
}
export class EcommerceProductoBusquedaParamsDto {
  @IsString()
  nivel1!: string

  @IsString()
  nivel2!: string

  @IsString()
  busqueda!: string

  @IsNumber()
  offset!: number

  @IsNumber()
  limit!: number

  @IsString()
  orderCodigo!: string
}

export class EcommerceProductosParamsDto {
  @IsString()
  @IsOptional()
  agrupacion?: string
  @IsString()
  @IsOptional()
  nivel1?: string
  @IsString()
  @IsOptional()
  nivel2?: string
  @IsString()
  @IsOptional()
  nivel1Cod?: string
  @IsString()
  @IsOptional()
  nivel2Cod?: string
}
